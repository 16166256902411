import './Odyssey.scss'
import { Button, Skeleton, Tabs } from 'antd'
import { useEffect, useState } from 'react';
import QRCode from 'qrcode.react'
import html2canvas from 'html2canvas'
import { sign } from '../../contract/methods';

const { TabPane } = Tabs;

const Veritcal = ({ code }) => {
    let [imgUrl, setImgUrl] = useState('')
    const downLoad= () => {
        var dlink = document.createElement('a')
        dlink.download = 'kepler invation share image'
        dlink.href = imgUrl
        dlink.dataset.downloadurl = ['image/png', dlink.download, dlink.href].join(':')
        document.body.appendChild(dlink)
        dlink.click()
        document.body.removeChild(dlink)
    }
    useEffect(() => {
        setImgUrl('')
        setTimeout(()=> {
            html2canvas(document.getElementById('qr-vertical'), {
                backgroundColor: null
            }).then((canvas) => {
                let img_tmp = canvas.toDataURL('image/png')
                setImgUrl(img_tmp)
            })
        }, 2000)
        
    }, [code])

    return (
        <div className='flex flex-middle w100 flex-column flex-center'>
            <div className='vertical-qrcode'>
                <div>
                {
                imgUrl ? 
                    <img src={imgUrl} alt="" className='qr-img' />: <Skeleton.Button active />
            }
                </div>
            
            </div>
            {
                imgUrl && <Button className='my-button m-t-20' onClick={downLoad}>Download</Button>
            }
            
            <div className="qrcode-box-vertical" id="qr-vertical">
                <img src={require('./image/qrcode.jpg')} alt="" />
                <div className="qrcode-bg p-l-5 p-r-5 p-t-5 p-b-0 bgf">
                    <QRCode
                        id="qrcode-v"
                        value={'https://' + window.location.host + '?code=' + code}
                        size={150}
                        fgColor="#000000"
                    />
                </div>

            </div>
        </div>
    )

}
const Horizontal = ({ code }) => {
    let [imgUrl, setImgUrl] = useState('')
    const downLoad= () => {
        var dlink = document.createElement('a')
        dlink.download = 'kepler invation share image'
        dlink.href = imgUrl
        dlink.dataset.downloadurl = ['image/png', dlink.download, dlink.href].join(':')
        document.body.appendChild(dlink)
        dlink.click()
        document.body.removeChild(dlink)
    }
    useEffect(() => {
        setImgUrl('')
        setTimeout(()=> {
            html2canvas(document.getElementById('qr-horizontal'), {
                backgroundColor: null
            }).then((canvas) => {
                let img_tmp = canvas.toDataURL('image/png')
                setImgUrl(img_tmp)
            })
        }, 2000)
        
    }, [code])
    return <div className='flex flex-center w100 flex-column'>
        {
            imgUrl ? <img src={imgUrl} alt="" /> : <Skeleton.Button active />
        }
        {
                imgUrl && <Button className='my-button m-t-20' onClick={downLoad}>Download</Button>
            }
        <div className="qrcode-box-horizontal" id="qr-horizontal">
            <img src={require('./image/qrcode_h.jpg')} alt="" />
            <div className="qrcode-bg p-l-3 p-r-3 p-t-3 p-b-0 bgf">
                <QRCode
                    id="qrcode-v"
                    value={'https://' + window.location.host + '?code=' + code}
                    size={50}

                    fgColor="#000000"
                />
            </div>
        </div>
    </div>
}
export default (props) => {
    let [active, setActive] = useState('1')
    useEffect(async()=> {
        if(!props.code) {
            props.close && props.close()
        }
    }, [props.code])
    return (
        <div>
            <Tabs defaultActiveKey={'1'} activeKey={active} onChange={e => { setActive(e) }} className='my-tab-mini' moreIcon={''}>
                <TabPane tab='Vertical plate' key="1">
                    <Veritcal code={props.code} />
                </TabPane>
                <TabPane tab='Horizontal plate' key="2">
                    <Horizontal code={props.code} />
                </TabPane>
            </Tabs>
        </div>
    )
}