import './Rewards.scss'
import Arb from './image/arb.png'
import Girl from './image/girl.svg'

export default () => {
    return <div className='rewards-text-area m-t-125 w100'>
        <div className="rewards-inner flex flex-center flex-between">

            <img src={Arb} alt="" with={508} />
            <div className="rewards-text flex flex-column">
                <div className="fz-64 cf fwb lh-72 m-fz-24 m-lh-30 "> Kepler AirDropBox
                    Referral Rewards
                </div>
                <div className="fz-32 fwb cf m-t-19 m-fz-19">Invitation Reward Rules</div>
            <div className="fz-16 fwb cf m-t-19 m-m-t-10">
                1. The upper limit of airdrops for invitation rewards is 250,000, first come first served while stocks last;
                <br/><br/>2. Users who meet the Kepler AirdropBox interaction condition address will get 5 boxes randomly (the address is in the database);
                <br/><br/>3. Invite friends, and friends who meet the Kepler AirdropBox interaction conditions can get 5 boxes and reward themselves with 2 boxes;
            </div>
            <div className="cf4 m-t-19 fz-14 m-m-t-10">*   To be added...</div>
            </div>
        </div>
        <img src={Girl} alt="" className='girl'/>
    </div>
}