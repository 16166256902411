import React from "react";
import discord from '../../assets/images/home/discord-light.svg'
import github from '../../assets/images/home/github-light.svg'
import telegram from '../../assets/images/home/telegram-light.svg'
import twitter from '../../assets/images/home/twitter-light.svg'
import medium from '../../assets/images/airdrop/medium.svg'
import youtube from '../../assets/images/home/youtube.svg'
import trustlook from '../../assets/images/newairdrop/trustlook.png'
import footerLogo from '../../assets/images/home/footer-logo.png'
import Logo from '../../assets/images/home/logo.svg'
import './Mintfooter.scss'
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <div data-scroll-section className="mint-footer m-t-10">
      <div className="footer-content">
        <div className="footer-content-left">
          <img className="footer-logo logo-w" src={Logo} alt="" />
        </div>
        <div className="footer-bottom flex-1 flex flex-middle gap-56 m-gap-18 fwb cf4 fz-12 ta">
        * This airdrop event is first come, first served while stocks last <br/>
        * KeplerHomes reserves the right to modify and interpret this AirdropBox airdrop.
        </div>
        <div className="footer-content-right flex flex-column flex-start">
          <div className="social-list">
            <a className="social-item m-r-5" href="https://github.com/kepler-homes"><img src={github} alt="" /></a>
            <a className="social-item m-r-5" href="https://medium.com/@KeplerHomes" target="_blank"><img src={medium} alt="" /></a>
            <a className="social-item m-r-5" href="https://twitter.com/KeplerHomes" target="_blank"><img src={twitter} alt="" /></a>
          </div>
          <div className="copyright cf">Kepler © 2023, All rights reserved</div>
        </div>
      </div>
    </div>
  )
}

export default Footer;
