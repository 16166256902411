import { providerOptions } from "../wallet/helper/providerOptions";

export const baseUrl = 'https://api-beta.kepler.homes'
export const chainId = 421613;
export const arb_network = {
    name: 'ARB',
    params: {
      chainId: '421613',
      chainName: 'ARB TestNet',
      nativeCurrency: {
        name: 'Ethereum',
        symbol: 'GoerliETH',
        decimals: 18,
      },
      rpcUrls: ['https://goerli-rollup.arbitrum.io/rpc'],
      blockExplorerUrls: ['https://goerli.arbiscan.io'],
    },
    networkId: 421613,
    httpProviderURL: 'https://goerli-rollup.arbitrum.io/rpc',
    connectors: {
      network: 'arbitrum',
      cacheProvider: true,
      providerOptions: providerOptions({
        walletconnectOptions: {
          rpc: {
            421613: 'https://goerli-rollup.arbitrum.io/rpc'
          }
        }
      })
    }
  }