
import './Progress.scss'
import cloud from './image/cloud.png'
import { useEffect, useState } from 'react'
import { queryBalances } from '../../contract/methods/boxairdrop'
import { numFormat } from '../../lib/util'
const Progress = ({className,total, color, text,bal}) => {

    return <div className={"progress " + className}>
        <div className="w100 ta cf fz-16">{text}</div>
        <div className={'progress-outter w100 m-t-15 '+color}>
              <div className="progress-outter-border w100">
              <div className="progress-inside" style={{width: (((total-bal)/(total/100)) < 3 ? 3:((total-bal)/(total/100)))+'%'}}></div>
              </div>
        </div>                  
        <div className="ta fz-12 cf m-t-11">
        { numFormat(total-bal)} / {numFormat(total)}  (raffled / total)
        </div>
    </div>
}
export default () => {
    let [bal, setBal] = useState({})
    useEffect(async()=> {
        let {data:bal} = await queryBalances()
        setBal(bal)
    }, [])
    return <div className="progress-area w100 m-t-100 flex flex-column flex-center">
          <div className="progress-inner flex flex-around gap-30">
              <Progress  color="yellow" total="750000" bal={bal.user_box_amount?(bal.user_box_amount <0 ? 0:(bal.user_box_amount)):0} text={<span>Total Rewards <span className='ce'>750,000</span> Kepler AirDropBox</span>}/>
              <Progress  color="green" total="250000"  bal={bal.referrer_box_amount || 0} text={<span>Kepler AirDropBox <span className='ce'>250,000</span> Referral Rewards</span>}/>
          </div>
          <img src={cloud} alt=""  className='cloud' />
    </div>
}