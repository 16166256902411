import React, { useState, useEffect, useCallback } from 'react';
import logo from '../../assets/images/nav/logo.svg'
import lines from '../../assets/images/nav/lines.svg'
import homeicon from '../../assets/images/arb/home.svg'
import airdropicon from '../../assets/images/arb/airdrop.svg'
import marketplaceicon from '../../assets/images/arb/marketplace.svg'
import wlicon from '../../assets/images/arb/wl.svg'
import homeiconactive from '../../assets/images/arb/homeactive.svg'
import airdropiconactive from '../../assets/images/arb/airdropactive.svg'
import marketplaceiconactive from '../../assets/images/arb/marketplaceactive.svg'
import wliconactive from '../../assets/images/arb/wlactive.svg'
import presaleicon from '../../assets/images/arb/presale.svg'
import soon from '../../assets/images/arb/soon.svg'
import hot from '../../assets/images/arb/hot.svg'
import iconArrow from '../../assets/images/newairdrop/icon-arrow.svg'
import openSea from '../../assets/images/newairdrop/opensea.png'
import Element from '../../assets/images/newairdrop/element.png'
import Market from '../../assets/images/newairdrop/market.jpg'
import classnames from 'classnames'
import './Airnav.scss'
import { useTranslation } from 'react-i18next'
import ConnectWallet from '../ConnectWalletUi';
import { connect } from 'react-redux'
import notification from '../notification'

import Bus from '../../lib/eventBus'
import { NavLink } from 'react-router-dom';
import { post, get } from '../../http';
import { ChainIdMap } from '../../lib/util';
import store, { setFirst, setToken, setUserInfo } from '../../store';
import { Drawer, Dropdown, Menu } from 'antd';
import box_icon from '../../assets/images/newairdrop/keplerairdrop.jpg'
import key_icon from '../../assets/images/newairdrop/keplerkey.jpg'
import pfp_icon from '../../assets/images/newairdrop/keplerpfp.jpg'

let communityList = [
  {
    name: 'trustlook',
    icon: require('../../assets/images/airdrop/trust.svg').default,
    activeIcon: require('../../assets/images/airdrop/trust_active.svg').default,
    link: 'https://resources.kepler.homes/kepler-audit-trustlook.pdf'
  },
  {
    name: 'twitter',
    icon: require('../../assets/images/airdrop/twitter.svg').default,
    activeIcon: require('../../assets/images/airdrop/twitter_active.svg').default,
    link: 'https://twitter.com/KeplerHomes'
  },
  // {
  //   name: 'telegram',
  //   icon: require('../../assets/images/airdrop/telegram.svg').default,
  //   activeIcon: require('../../assets/images/airdrop/telegram_active.svg').default,
  //   link: 'https://t.me/KeplerHomes'
  // },
  {
    name: 'discord',
    icon: require('../../assets/images/airdrop/discord.svg').default,
    activeIcon: require('../../assets/images/airdrop/discord_active.svg').default,
    link: 'https://discord.com/invite/keplerhms'
  },
  {
    name: 'medium',
    icon: require('../../assets/images/airdrop/medium.svg').default,
    activeIcon: require('../../assets/images/airdrop/medium_active.svg').default,
    link: 'https://medium.com/@KeplerHomes'
  }

]
const navList = [
  {
    isout: true,
    title: 'Home',
    path: 'https://kepler.homes',
    icon: homeicon,
    activeicon: homeiconactive,
    issoon: false,
    ishot: false,
    disabled: false
  },
  {
    isout: false,
    title: 'Airdrop',
    path: '/',
    icon: airdropicon,
    activeicon: airdropiconactive,
    issoon: false,
    ishot: false,
    disabled: false
  },
  // {
  //   isout: true,
  //   title: 'Marketplace',
  //   path: 'https://market.kepler.homes',
  //   icon: marketplaceicon,
  //   activeicon: marketplaceiconactive,
  //   issoon: false,
  //   ishot: false,
  //   disabled: false
  // },
  {
    isout: true,
    title: 'AllowList',
    path: 'https://passport-arbitrum.kepler.homes',
    icon: wlicon,
    activeicon: wliconactive,
    issoon: false,
    ishot: false,
    disabled: false
  },
  // {
  //   isout: true,
  //   title: 'Market',
  //   path: 'https://market.kepler.homes',
  //   icon: marketplaceiconactive,
  //   activeicon: marketplaceiconactive,
  //   issoon: false,
  //   ishot: false,
  //   disabled: false
  // },
  {
    isout: true,
    title: 'Presale',
    path: 'https://presale-arb.kepler.homes',
    icon: presaleicon,
    activeicon: presaleicon,
    issoon: false,
    ishot: true,
    disabled: false,
    color: 'rgba(140, 250, 80, 1)'
  }
]
function Header(props) {
  let { t, i18n } = useTranslation()
  let language = i18n.language.split('-')[0]
  let [hasBg, setBg] = useState(false)
  const [isConnectWalletVisible, setIsConnectWalletVisible] = useState(false)
  let [width, setWidth] = useState(window.innerWidth)
  let [open, setOpen] = useState(false)


  const handleConnectWalletOk = () => {
    setIsConnectWalletVisible(false)
  }

  const handleConnectCancel = () => {
    setIsConnectWalletVisible(false)
  }

  const handleConnectWallet = () => {
    setIsConnectWalletVisible(true)
  }
  const copyAddress = useCallback(async () => {
    if (!props.account) {
      notification.error({
        message: t('Please connect your wallet first')
      });
      return
    }
    await navigator.clipboard.writeText('https://' + window.location.host + '/nft-mint?address=' + props.account);
    notification.success({
      message: t('Invitation link has been generated, please send it to your friends!'),
    });
  }, [props.account]);
  useEffect(() => {
    window.addEventListener('scroll', function () {
      if (document.documentElement.scrollTop > 100) {
        setBg(true)
      } else {
        setBg(false)
      }
    })
    window.addEventListener('resize', ()=> {
      console.log(window.innerWidth)
      setWidth(window.innerWidth)
    })
  }, [])



  return (
    <div className='nav-bar-area'>
      <div className={classnames(["airdrop-header flex flex-between", { "has-bg": hasBg }])}>
        <div className="logo-wrap flex flex-center flex-middle flex-between logo-wrap-fold" >
          {/* <a href="/" className='logo'> */}
          <img src={logo} alt="" className='logo' />
        </div>

        <div className="flex-1 flex flex-middle">
          {
            navList.map((item, index) => {
              return (
                item.disabled ? (
                  <span className="nav-item cf fwb  fz-16 m-l-35 islink" key={index}>
                    <img src={item.icon} className="m-r-3 unselected"></img>
                    <img src={item.activeicon} className="m-r-3 selected"></img>
                    {item.title}
                    {item.issoon && <img src={soon} alt="" className='soon' />}
                    {item.ishot && <img src={hot} alt="" className='soon' />}
                  </span>
                ) :
                  item.isout ? (
                    <a className="nav-item cf fwb  fz-16 m-l-35 islink" style={{color: item.color?item.color:''}} target="_blank" href={item.path} key={index}>
                      <img src={item.icon} className="m-r-3 unselected"></img>
                      <img src={item.activeicon} className="m-r-3 selected"></img>
                      {item.title}
                      {item.issoon && <img src={soon} alt="" className='soon' />}
                      {item.ishot && <img src={hot} alt="" className='soon' />}
                    </a>
                  ) : (
                    <NavLink className="nav-item cf fwb  fz-16 m-l-35 islink" to={item.path} key={index}>
                      <img src={item.icon} className="m-r-3 unselected"></img>
                      <img src={item.activeicon} className="m-r-3 selected"></img>
                      {item.title}
                      {item.issoon && <img src={soon} alt="" className='soon' />}
                      {item.ishot && <img src={hot} alt="" className='soon' />}
                    </NavLink>
                  ))
            })
          }
          <Dropdown placement="bottom" overlayClassName="header-nav-dropdown" atip overlay={
                <Menu>
                  <Menu.Item>
                    <a className="nav-item cf  fz-16  islink" target="_blank" href="https://market.kepler.homes/KeplerProtagonist">
                      <div className="menu-title">
                        <img src={pfp_icon} alt="" className='nav-icon'/>
                        KeplerPFP</div>
                    </a>
                  </Menu.Item>
                  <Menu.Item>
                  <a className="nav-item cf  fz-16  islink" target="_blank" href="https://market.kepler.homes/AirdropKey">
                  <div className="menu-title">
                  <img src={key_icon} alt="" className='nav-icon'/>AirdropKey</div>
                  </a>
                  </Menu.Item>

                  <Menu.Item>
                    <a className="nav-item cf  fz-16  islink" target="_blank" href="https://market.kepler.homes/KeplerAirdropBox">
                    <div className="menu-title">
                    <img src={box_icon} alt="" className='nav-icon'/>
                      AirdropBox</div>
                    </a>
                  </Menu.Item>
                </Menu>
              }>
                <a className="nav-item cf fwb  fz-16 m-l-35 islink" 
                onClick={e => e.preventDefault()}
                href="/"
                >
                  <img src={Market} alt="" className="m-r-3 market-icon" />
                  Market <img className="icon-arrow" src={iconArrow} alt="" />
                </a>
              </Dropdown>
             <Dropdown placement="bottom" overlayClassName="header-nav-dropdown" atip overlay={
                <Menu>
                  <Menu.Item>
                    <a className="nav-item cf  fz-16  islink" target="_blank" href="https://element.market/collections/Kepler_PFP_Arb">
                      <div className="menu-title"><img src={pfp_icon} alt="" className='nav-icon'/>KeplerPFP</div>
                    </a>
                  </Menu.Item>
                  <Menu.Item>
                  <a className="nav-item cf  fz-16  islink" target="_blank" href="https://element.market/collections/kepler-airdropkey-arb">
                  <div className="menu-title"><img src={key_icon} alt="" className='nav-icon'/>AirdropKey</div>
                  </a>
                  </Menu.Item>

                  <Menu.Item>
                    <a className="nav-item cf  fz-16  islink" target="_blank" href="https://element.market/collections/Kepler_AirDropBox">
                    <div className="menu-title"><img src={box_icon} alt="" className='nav-icon'/>AirdropBox</div>
                    </a>
                  </Menu.Item>
                </Menu>
              }>
                <a className="nav-item cf fwb  fz-16 m-l-35 islink" 
                onClick={e => e.preventDefault()}
                href="/"
                >
                  <img src={Element} alt="" className="m-r-3 market-icon" />
                  Element <img className="icon-arrow" src={iconArrow} alt="" />
                </a>
              </Dropdown>
              <Dropdown placement="bottom" overlayClassName="header-nav-dropdown" atip overlay={
                <Menu>
                  <Menu.Item>
                    <a className="nav-item cf  fz-16  islink" target="_blank" href="https://opensea.io/collection/kepler-pfp-arb">
                      <div className="menu-title"><img src={pfp_icon} alt="" className='nav-icon'/>KeplerPFP</div>
                    </a>
                  </Menu.Item>
                  <Menu.Item>
                  <a className="nav-item cf  fz-16  islink" target="_blank" href="https://opensea.io/collection/kepler-airdropkey-arb">
                  <div className="menu-title"><img src={key_icon} alt="" className='nav-icon'/>AirdropKey</div>
                  </a>
                  </Menu.Item>

                  <Menu.Item>
                    <a className="nav-item cf  fz-16  islink" target="_blank" href="https://opensea.io/collection/kepler-airdropbox-arb">
                    <div className="menu-title"><img src={box_icon} alt="" className='nav-icon'/>AirdropBox</div>
                    </a>
                  </Menu.Item>
                </Menu>
              }>
                <a className="nav-item cf fwb fz-16 m-l-35 islink" 
                onClick={e => e.preventDefault()}
                href="/"
                >
                  <img src={openSea} alt="" className="m-r-3 market-icon"/>
                  OpenSea
                   <img className="icon-arrow" src={iconArrow} alt="" />
                </a>
              </Dropdown>
        </div>
        <div className="header-right">
          {/* <span className='fwb cf fz-14 m-r-5'>Audit By</span> */}
          {
            communityList.map(item => {
              return (
                <a className="m-r-26 community_icon " href={item.link} target="_blank" key={item.link}>
                  <img src={item.icon} alt="" className='icon' />
                  <img src={item.activeIcon} alt="" className='active_icon' />
                </a>
              )
            })
          }

        </div>
        <div className='connect m-l-4'>
          {
            width > 1280 &&
          <ConnectWallet hideChain={['BSC', 'Avalanche', 'Polygon', 'Solana']} isVisible={isConnectWalletVisible} handleOk={handleConnectWalletOk} handleCancel={handleConnectCancel} />
          }
        </div>
      </div>
      <div className={classnames(["airdrop-header-mini flex flex-between flex-center", { "has-bg": hasBg }])}>
        <span></span>
        <img src={logo} alt="" className='logo' />
        <div className="menu-lines p-r-20 pointer" onClick={() => setOpen(true)}>
          <img src={lines} alt="" />
        </div>

        <Drawer closable={false} title={<img src={logo} alt="" className='logo' />} placement="right" onClose={() => { setOpen(false) }} visible={open}>
          {
            navList.map((item, index) => {
              return (
                item.disabled ? (
                  <span className="nav-item cf fwb  fz-16 m-l-20 m-b-20 islink" key={index}>
                    <img src={item.icon} className="m-r-3 unselected"></img>
                    <img src={item.activeicon} className="m-r-3 selected"></img>
                    {item.title}
                    {item.issoon && <img src={soon} alt="" className='soon' />}
                    {item.ishot && <img src={hot} alt="" className='soon' />}
                  </span>
                ) :
                  item.isout ? (
                    <a className="nav-item cf fwb  fz-16 m-l-20 m-b-20 islink" style={{color: item.color?item.color:''}} target="_blank" href={item.path} key={index}>
                      <img src={item.icon} className="m-r-3 unselected"></img>
                      <img src={item.activeicon} className="m-r-3 selected"></img>
                      {item.title}
                      {item.issoon && <img src={soon} alt="" className='soon' />}
                      {item.ishot && <img src={hot} alt="" className='soon' />}
                    </a>
                  ) : (
                    <NavLink className="nav-item cf fwb  fz-16 m-l-20 m-b-20 islink" to={item.path} key={index}>
                      <img src={item.icon} className="m-r-3 unselected"></img>
                      <img src={item.activeicon} className="m-r-3 selected"></img>
                      {item.title}
                      {item.issoon && <img src={soon} alt="" className='soon' />}
                      {item.ishot && <img src={hot} alt="" className='soon' />}
                    </NavLink>
                  ))
            })
          }

<Dropdown placement="bottom" overlayClassName="header-nav-dropdown" atip overlay={
                <Menu>
                  <Menu.Item>
                    <a className="nav-item cf  fz-16  islink" target="_blank" href="https://market.kepler.homes/KeplerProtagonist">
                      <div className="menu-title">
                        <img src={pfp_icon} alt="" className='nav-icon'/>
                        KeplerPFP</div>
                    </a>
                  </Menu.Item>
                  <Menu.Item>
                  <a className="nav-item cf  fz-16  islink" target="_blank" href="https://market.kepler.homes/AirdropKey">
                  <div className="menu-title">
                  <img src={key_icon} alt="" className='nav-icon'/>AirdropKey</div>
                  </a>
                  </Menu.Item>

                  <Menu.Item>
                    <a className="nav-item cf  fz-16  islink" target="_blank" href="https://market.kepler.homes/KeplerAirdropBox">
                    <div className="menu-title">
                    <img src={box_icon} alt="" className='nav-icon'/>
                      AirdropBox</div>
                    </a>
                  </Menu.Item>
                </Menu>
              }>
                <a className="nav-item cf fwb  fz-16 islink m-l-20 m-b-20" 
                onClick={e => e.preventDefault()}
                href="/"
                >
                  <img src={Market} alt="" className="m-r-3 market-icon" />
                  Market <img className="icon-arrow" src={iconArrow} alt="" />
                </a>
              </Dropdown>
             <Dropdown placement="bottom" overlayClassName="header-nav-dropdown" atip overlay={
                <Menu>
                  <Menu.Item>
                    <a className="nav-item cf  fz-16  islink" target="_blank" href="https://element.market/collections/Kepler_PFP_Arb">
                      <div className="menu-title"><img src={pfp_icon} alt="" className='nav-icon'/>KeplerPFP</div>
                    </a>
                  </Menu.Item>
                  <Menu.Item>
                  <a className="nav-item cf  fz-16  islink" target="_blank" href="https://element.market/collections/kepler-airdropkey-arb">
                  <div className="menu-title"><img src={key_icon} alt="" className='nav-icon'/>AirdropKey</div>
                  </a>
                  </Menu.Item>

                  <Menu.Item>
                    <a className="nav-item cf  fz-16  islink" target="_blank" href="https://element.market/collections/Kepler_AirDropBox">
                    <div className="menu-title"><img src={box_icon} alt="" className='nav-icon'/>AirdropBox</div>
                    </a>
                  </Menu.Item>
                </Menu>
              }>
                <a className="nav-item cf fwb  fz-16 islink m-l-20 m-b-20" 
                onClick={e => e.preventDefault()}
                href="/"
                >
                  <img src={Element} alt="" className="m-r-3 market-icon" />
                  Element <img className="icon-arrow" src={iconArrow} alt="" />
                </a>
              </Dropdown>
              <Dropdown placement="bottom" overlayClassName="header-nav-dropdown" atip overlay={
                <Menu>
                  <Menu.Item>
                    <a className="nav-item cf  fz-16  islink" target="_blank" href="https://opensea.io/collection/kepler-pfp-arb">
                      <div className="menu-title"><img src={pfp_icon} alt="" className='nav-icon'/>KeplerPFP</div>
                    </a>
                  </Menu.Item>
                  <Menu.Item>
                  <a className="nav-item cf  fz-16  islink" target="_blank" href="https://opensea.io/collection/kepler-airdropkey-arb">
                  <div className="menu-title"><img src={key_icon} alt="" className='nav-icon'/>AirdropKey</div>
                  </a>
                  </Menu.Item>

                  <Menu.Item>
                    <a className="nav-item cf  fz-16  islink" target="_blank" href="https://opensea.io/collection/kepler-airdropbox-arb">
                    <div className="menu-title"><img src={box_icon} alt="" className='nav-icon'/>AirdropBox</div>
                    </a>
                  </Menu.Item>
                </Menu>
              }>
                <a className="nav-item cf fwb fz-16 islink m-l-20 m-b-20" 
                onClick={e => e.preventDefault()}
                href="/"
                >
                  <img src={openSea} alt="" className="m-r-3 market-icon"/>
                  OpenSea
                   <img className="icon-arrow" src={iconArrow} alt="" />
                </a>
              </Dropdown>
          <div className='m-t-60'>
            <div className='connect'>
            {
            width <= 1280 &&
              <ConnectWallet hideChain={['BSC', 'Avalanche', 'Polygon', 'Solana']} isVisible={isConnectWalletVisible} handleOk={handleConnectWalletOk} handleCancel={handleConnectCancel} />
            }
            </div>
          </div>

          <div className="header-mini-right flex flex-middle gap-26">
            {
              communityList.map(item => {
                return (
                  <a className="community_icon " href={item.link} target="_blank" key={item.link}>
                    <img src={item.icon} alt="" className='icon' />
                    <img src={item.activeIcon} alt="" className='active_icon' />
                  </a>
                )
              })
            }
          </div>
        </Drawer>
      </div>
      <div className='hide' style={{display: 'none'}}>
      {
        width <= 1280 && !open && <ConnectWallet hideChain={['BSC', 'Avalanche', 'Polygon', 'Solana']} isVisible={isConnectWalletVisible} handleOk={handleConnectWalletOk} handleCancel={handleConnectCancel} />
      }
      </div>
      


      {
        hasBg && <div className='offset'></div>
      }


    </div>
  )
}

export default connect(
  (state, props) => {
    return { ...state, ...props }
  }
)(
  Header
);

