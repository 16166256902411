import BSC_ADDRESS  from '../testnet/bscAddress'
import GOERLI_ADDRESS  from '../testnet/GoerliAddress'
import MUMBAI_ADDRESS  from '../testnet/MumbaiAddress'
import FUJI_ADDRESS  from '../testnet/FujiAddress'
import ARBITRUM_ADDRESS  from '../testnet/arbitrumAddress'

export function getAddress (chain) {
  return ARBITRUM_ADDRESS
}

export function getCurAddress () {
  // const chain = localStorage.getItem('kepler_chain') || 'Arbitrum'
  return getAddress()
}
