import './Odyssey.scss'
import box1 from './image/box/1.svg'
import box2 from './image/box/2.svg'
import box3 from './image/box/3.svg'
import box4 from './image/box/4.svg'
import box5 from './image/box/5.svg'
import cloud from './image/cloudmini.png'
import soon from '../../assets/images/arb/soon.svg'
import { Button, Popover, Tooltip, Skeleton, Tabs } from 'antd'
import Modal from '../../components/Base/Modal'
import { boxList } from '../../lib/const'
import { formatTime, showConnectWallet } from '../../lib/util'
import { useCallback, useEffect, useState } from 'react'
import { get } from '../../http'
import notification from '../../components/notification'
import useInterval from '@use-it/interval'
import { draw, queryBalances, queryReferrerTokenIdValues, queryUserTokenIdValues } from '../../contract/methods/boxairdrop'
import { useLocation } from 'react-router-dom'
import qs from "query-string";
import Qrcode from './Qrcode'
import { sign } from '../../contract/methods'
import { format } from 'date-fns'
import Claimbox from './Claimbox/index'

const { TabPane } = Tabs;
const twtext = (code)  => `🧡@KeplerHomes AirdropBox event for %23Arbitrum ecological users is here. A total of 550,000 addresses are eligible for %23airdrop, and 5 types of AirDropbox with different scarcity can be issued.
%0a%0a💙Invitation code: ${code}%0a
🏆Airdrop Portal:👉 https://${window.location.host}?code=${code}`


const Box = ({ box, name, num }) => {
    return (
        <div className='box-item-wrap'>
            <div className='box-item'>
                <img src={cloud} alt="" className='cloud-bottom' />
                <img src={cloud} alt="" className='cloud-top' />
                <img src={box} alt="" className='box-img' />

            </div>
            <div className="cf fwb ta fz-11 num">{num}</div>
            <div className="cf fwb ta fz-11 name">{name}</div>
        </div>

    )
}
export default (props) => {

    let location = useLocation()
    let [showReward, setShowReward] = useState(false)
    let [showQrcode, setShowQrcode] = useState(false)
    let [showWarning, setShowWaring] = useState(false)
    let [loadingContent, setLoadingConent] = useState(true)
    let [loading, setLoading] = useState(false)
    let [tokenIds, setTokenIds] = useState({})
    let [errTip, setErrTips] = useState('')
    let [refresh, setRefresh] = useState(0)
    let [invitation, setInvation] = useState(qs.parse(location.search).code || qs.parse(location.search).ref)
    let [refTokenNum, setRefTokenNum] = useState(0)
    let [totalTokenNum, setTotalTokenNum] = useState(0)
    let [recordList, setRecordList] = useState([])
    let [bal, setBal] = useState({})

    let [code, setCode] = useState(props.code)
    const calNum = (v) => {
        if (typeof (v) == 'undefined') {
            return 0
        } else {
            return v * 1
        }
    }
    const reduceBox = (data) => {
        let n1 = 0
        let n2 = 0
        boxList.map((item, index) => {
            let v = 
            n1 += calNum(data['user_'+item.name.toLowerCase()+'_box_amount'])
            n2 += calNum(data['referrer_'+item.name.toLowerCase()+'_box_amount'])
        })
        return {
            total: data.user.toLowerCase() == props.account.toLowerCase()  ? n1:n2,
            type: data.user.toLowerCase() == props.account.toLowerCase() ? 'Raffle':'Invite reward'
        }
    }
    const copyCode = useCallback(async () => {
        if (!props.account) {
            notification.error({
                message: ('Please connect your wallet first')
            });
            return
        }
        await navigator.clipboard.writeText(`https://${window.location.host}?code=${code}`);
        notification.success({
            message: ('Invitation link has been generated, please send it to your friends!'),
        });
        // await navigator.clipboard.writeText(`https://${location.}`code);
        // notification.success({
        //     message: ('Copied'),
        // });
    }, [code]);
    const toDrawBox = async () => {
        setLoading(true)
        try {
            let signature = await sign('Kepler Box Airdrop')
            draw(invitation||'', signature).then(res => {
                setRefresh(refresh + 1)
            }).catch(err => {
                console.log(err)
                setErrTips(err.msg)
            }).finally(res => {
                setLoading(false)
            })
        } catch (err) {
            console.log(err)
            setErrTips(err.msg)
            setLoading(false)
        }
    }
    useEffect(() => {
        setCode(props.code)
    }, [props.code])

    useInterval(() => { setLoadingConent(false) }, 1000)
    useEffect(async()=> {
        let {data:bal} = await queryBalances()
        setBal(bal)
    }, [])
    useEffect(async () => {
        if (props.account) {
            let { data: ids } = await queryUserTokenIdValues(props.account)
            // let tokenNum = await queryReferrerTokenIdValues(props.account)
            console.log(ids)
            // console.log(tokenNum)
            setTokenIds(ids)
            let total = 0
            boxList.map(item => {
                total += calNum(ids[item.name.toLowerCase() + '_box_amount'])
            })
            setTotalTokenNum(total)
            let { data: rewardrecord } = await get('/api/airdrop/evm/box/draws', { address: props.account })
            console.log(rewardrecord)
            setRecordList(rewardrecord.reverse())
            let referNum = 0
            rewardrecord.map(item => {
                referNum += (reduceBox(item).type == 'Invite reward' ? reduceBox(item).total:0)*1
            })
            setRefTokenNum(referNum)
        } else {
            setTokenIds({})
            setRecordList([])
            setRefTokenNum(0)
        }
    }, [props.account, refresh])
    return (
        <div className="odyssey p-t-40 flex flex-center">
            <div className="man">
                {
                    props.banner
                }
                {
                    props.showopacity && <div className="man-bg"></div>
                }

            </div>
            {
                props.loading || loadingContent ? <div className="odyssey-content flex flex-column flex-first">
                    <Skeleton active />
                </div> :
                    <div className="odyssey-content">
                        {
                            props.account && code && boxList.some(item => tokenIds[item.name?.toLowerCase() + '_box_amount']) ? (
                                <>
                                    <div className="content-title flex flex-center flex flex-between">
                                        <span className='cf fz-20 fwb'>My Referral Code</span>
                                        <div className="fz-13 fwb  flex flex-center">
                                            <Popover placement="bottomRight" content={<div className='flex flex-center'>
                                                <div>
                                                    <div className="fz-14">Total Rewards</div>
                                                    <div className="fz-18 fwb  m-t-5">{totalTokenNum} AirdropBox</div>
                                                </div>
                                                <div className='m-l-50'>
                                                    <div className="fz-14">Invite Rewards</div>
                                                    <div className="fz-18 fwb m-t-5">{refTokenNum} AirdropBox</div>
                                                </div>

                                                <div className='m-l-50'>
                                                    <div className="fz-14">Claimable</div>
                                                    <div className="fz-18 fwb m-t-5">{totalTokenNum} AirdropBox</div>
                                                </div>
                                            </div>} title="" trigger="hover">
                                                <span className='ce pointer'>Details {'>'}</span>
                                            </Popover>

                                            <span className="line m-l-8 m-r-8"></span>
                                            <Popover placement="bottomRight" trigger="hover" content={<table className='w100' style={{maxHeight: '400px', overflow: 'scroll'}}>
                                                <thead><tr className='cb6'>
                                                    <th className='tl'>Time (UTC)</th>
                                                    <th className='tr p-l-20 p-r-20'>Amount</th>
                                                    <th className='tr'>Type</th>
                                                </tr></thead>
                                                <tbody >
                                                {
                                                    recordList.length > 0 ? (
                                                        recordList.map((item, index) => {
                                                            return <tr className='fz-14 fwb ' key={index}>
                                                                <td className='tl p-t-10 p-b-10'>{formatTime(new Date(item.created).getTime()/1000)}</td>
                                                                <td className='p-l-20 p-r-20 tr p-t-10 p-b-10'>+ {reduceBox(item).total} boxes</td>
                                                                <td className='tr p-t-10 p-b-10 pointer'>
                                                                    {
                                                                        reduceBox(item).type == 'Raffle' ? reduceBox(item).type:<Tooltip title={'Invitee:'+item.user}>{reduceBox(item).type}</Tooltip>
                                                                    }
                                                                    </td>
                                                            </tr>
                                                        })
                                                    ) : (
                                                        <tr className='fz-14 fwb '>
                                                            <td className='tl p-t-10 p-b-10' rowSpan={3}><span className="c06">No Data</span></td>
                                                        </tr>
                                                    )
                                                }</tbody>
                                            </table>}>
                                                <span className='ce pointer' >Reward history {'>'}</span>
                                            </Popover>

                                            {/* <span className="line m-l-8 m-r-8"></span> */}
                                            {/* <Popover placement="bottomRight" content={<table className='w100'>
                                    <tr className='cb6'>
                                            <th className='tl'>Time (UTC)</th>
                                            <th className='tr p-l-20 p-r-20'>Amount</th>
                                            <th className='tr'>Type</th>
                                        </tr>
                                        <tr className='fz-14 fwb '>
                                            <td className='tl p-t-10 p-b-10'>19:26:59 12/02 2023</td>
                                            <td className='p-l-20 p-r-20 tr p-t-10 p-b-10'>+ 2 boxies</td>
                                            <td className='tr p-t-10 p-b-10'>Odyssey Airdrop</td>
                                        </tr>
                                        <tr className='fz-14 fwb '>
                                            <td className='tl p-t-10 p-b-10'>19:26:59 12/02 2023</td>
                                            <td className='p-l-20 p-r-20 tr p-t-10 p-b-10'>+ 2 boxies</td>
                                            <td className='tr p-t-10 p-b-10'>Odyssey Airdrop</td>
                                        </tr>
                                        <tr className='fz-14 fwb '>
                                            <td className='tl p-t-10 p-b-10'>19:26:59 12/02 2023</td>
                                            <td className='p-l-20 p-r-20 tr p-t-10 p-b-10'>+ 2 boxies</td>
                                            <td className='tr p-t-10 p-b-10'>Odyssey Airdrop</td>
                                        </tr>
                                    </table>}>
                                        <span className='cf pointer'>Claim history {'>'}</span>
                                    </Popover> */}
                                            {/* <Popover placement="bottomRight" content={<div className='c006 fz-14 '>No Data</div>}>
                                        <span className='cf pointer'>Claim history {'>'}</span>
                                    </Popover> */}

                                        </div>
                                    </div>
                                    <div className="invite-code-area m-t-16 flex flex-center p-l-24 flex-between p-r-10">
                                        <span className='ce fz-24 m-fz-20 fwb flex invite-code'>
                                            {code}
                                            <Tooltip title="Invite friends and get extra box rewards">
                                                <img src={require('./image/copy.svg').default} alt="copy" className='m-l-10 pointer' onClick={copyCode} />
                                            </Tooltip>

                                            {/* <Tooltip title="click to share QRCode image">
                                                <img src={require('./image/img.svg').default} alt="copy" className='m-l-10 pointer' onClick={() => setShowQrcode(true)} />
                                            </Tooltip> */}
                                        </span>
                                        <span className='flex flex-center'>
                                        <Tooltip title="Invite friends and get extra box rewards">
                                            <span className="share-twitter flex flex-center p-l-12 p-r-12 pointer m-r-5" target="_blank" onClick={() => setShowQrcode(true)}>
                                                {/* <img src={require('./image/img.svg').default} alt="copy" className='m-r-5 pointer'  /> */}
                                                <span className="cf fwb fz-14 m-fz-12 flex">Share invitation </span>
                                            </span>
                                            </Tooltip>

                                            <a className="share-twitter flex flex-center p-l-12 p-r-12 pointer" target="_blank" href={`https://twitter.com/intent/tweet?text=${twtext(code)}`}>
                                                <img src={require('./image/twitter.svg').default} alt="" className='m-r-5' />
                                                <span className="ct fwb fz-14 m-fz-12 flex">Share<span className='show-p m-l-5'> your stats</span>   </span>
                                            </a>

                                        </span>
                                    </div>
                                    <div className="flex flex-center flex-middle w100 m-t-24">
                                        <span className="cf fwb fz-14 flex flex-center">
                                            My Invited Rewards
                                            <Tooltip title="For each successful invitation to a whitelist user to participate in this airdrop, you will receive an additional 2 boxes">
                                                <img src={require('./image/question.svg').default} alt="" className='m-l-5' />
                                            </Tooltip>

                                        </span>
                                        <span className='fz-24 fwb cf m-l-20 m-r-20'>{refTokenNum}</span>
                                    </div>
                                    <div className="fz-16 fwb cf ta m-t-12 flex  flex-center flex-middle total-wrap-area">
                                        My AirDropBox （Total AirDropBox: {totalTokenNum}）

                                        <Claimbox tokenIds={tokenIds}/>
                                    </div>
                                </>
                            ) : <div className='flex flex-column flex-center'>
                                {
                                    props.code && <div className='tips-text cf m-b-40'>
                                        <div className="ta fz-22 fwb">Congratulations!</div>
                                        <div className="fz-14 ta m-t-10">
                                            You are lucky to be on the airdrop white list, and you can randomly draw 5 boxes. After the draw, you can invite your friends on the whitelist to participate to get extra boxes too.
                                        </div>
                                    </div>
                                }
                                <div className="ta cf4 fz-12 m-b-9 w100  ">Please input invitation code (not required) </div>
                                <input type="text" placeholder='Please lnput invitation code' className="invite-input cf fwb" value={invitation} onChange={(e) => {
                                    setInvation(e.target.value)
                                    setErrTips('')
                                }} />
                                {errTip && <span className='cr fz-14 m-t-4'>Error: {errTip == 'INVALID_CODE' ? 'Invalid invitation code' : errTip}</span>}
                                {
                                    props.account ? (
                                        props.code ? <Button className='invite-connect' onClick={toDrawBox} loading={loading} disabled={bal?.user_box_amount <=0 }>Raffle AirdropBox {bal?.user_box_amount <=0?'Ended':''}</Button> :
                                            <Button className='invite-connect' onClick={() => setShowWaring(true)}>Check Your Account</Button>
                                    ) :

                                        <Button className='invite-connect' onClick={showConnectWallet}>Connect Wallet</Button>
                                }
                            </div>
                        }
                        <div className='flex flex-center flex-middle boxes gap-26 m-t-56'>
                            {
                                props.account && props.code && boxList.map((item, index) => {
                                    console.log(item.name)
                                    console.log(tokenIds)
                                    return <Box box={item.img} num={tokenIds[item.name?.toLowerCase() + '_box_amount'] || 0} name={item.name} key={index} />
                                })
                            }

                        </div>

                        {
                            boxList.some(item => tokenIds[item.name?.toLowerCase() + '_box_amount']) && <div className="fz-12 fwb cf4 ta m-t-24 m-m-t-1">
                                 Successfully participated the  <a href="https://presale-arb.kepler.homes" target="_blank"><u className='cf'>Kepler Presale</u></a> can increase the probability of unpacking high attribute quality by 10-30%.
                            </div>
                        }


                    </div>
            }


            <Modal isVisible={showWarning} onClose={_ => setShowWaring(false)}>
                <div className='flex flex-column flex-center'>
                    <div className="fz-32 fwb ta">Sorry</div>
                    <div className="fz-16 fwb ta p-t-20 p-b-20">Sorry, you are not eligible for this Kepler AirDropBox</div>
                    <Button className="isee black-btn" onClick={_ => setShowWaring(false)}>I see</Button>
                </div>
            </Modal>

            <Modal isVisible={showQrcode} title="Share invitation" margin={1} onClose={_ => setShowQrcode(false)}>
                <Qrcode code={code} close={_ => setShowQrcode(false)} />
            </Modal>
        </div>
    )
}