import Web3 from 'web3'
import {bep20ABI} from '../abi/bep20'
import AvatarAirdrop from '../testnet/AvatarAirdrop'
import BoxAirdrop from '../testnet/BoxAirdrop'
import KeyAirdrop from '../testnet/KeyAirdrop'
import {getCurAddress}  from '../testnet/address'
import notification from '../../components/notification'
import getNetworkData, { chainSymbolMap } from '../../wallet/helper/getNetworkData';
import { createProviderController } from '../../wallet/web3/createProviderController'
import store from '../../store'
import { get, post } from '../../http'
import { ChainIdMap } from '../../lib/util'
const chain = localStorage.getItem('kepler_chain') || 'Arbitrum'

const httpProviderURL = 'https://data-seed-prebsc-1-s2.binance.org:8545/'
console.log(httpProviderURL)
// const provider = await createProviderController(chain).connect()
export const web3 = new Web3(new Web3.providers.HttpProvider(httpProviderURL))
// if(!web3) {
    
// }

function createWeb3(chain) {
  let httpProviderURL
  httpProviderURL = getNetworkData.getArbitrumNetwork().httpProviderURL
  
  return new Web3(new Web3.providers.HttpProvider(httpProviderURL))
}

function createCurWeb3() {
  const chain = store.getState().chain
  return createWeb3(chain)
}

// / 将string转成bytes32
const stringToBytes32 = (s) => {
  let result = web3.utils.fromAscii(s);
  while (result.length < 66) {
      result = result + "0";
  }
  return result;
}


export function balanceOf (contractAddress, address) {
  const web3 = createCurWeb3()
  return new web3.eth.Contract(bep20ABI, contractAddress).methods.balanceOf(address).call()
}




// Airdrop
export function queryUserTokenIdValues(address) {
  return get('/api/airdrop/evm/box/user', {address, chainId:ChainIdMap[localStorage.getItem('kepler_chain')||'Arbitrum']})
}


export function queryReferrerTokenIdValues(user) {
  const web3 = createCurWeb3()
  return new web3.eth.Contract(BoxAirdrop, getCurAddress()[`BoxAirdrop`]).methods.queryReferrerTokenIdValues(user).call()
}
export function queryClaimRecords(user) {
  const web3 = createCurWeb3()
  return new web3.eth.Contract(BoxAirdrop, getCurAddress()[`BoxAirdrop`]).methods.queryCliamRecords(user).call()
}

export function queryClaimRecordsKeys(user) {
  const web3 = createCurWeb3()
  return new web3.eth.Contract(KeyAirdrop, getCurAddress()[`KeyAirdrop`]).methods.queryCliamRecords(user).call()
}
export function queryBalances() {
  return get('/api/airdrop/evm/box/global', {
    chainId:ChainIdMap[localStorage.getItem('kepler_chain')||'Arbitrum']
  })
}
export function draw(
  code,
  signature
) {
  console.log(code,
    signature)
  return new Promise(async (res, rej) => {
    try{
      post('/api/airdrop/evm/box/draw', {
        code,
        chainId: ChainIdMap[localStorage.getItem('kepler_chain')||'Arbitrum'],
        signature
      }).then(resp => {
        notification.success({
          message: 'Draw Box Success',
          description: 'please check your boxes'
        })
        res(resp.data)
      }).catch(err =>{
        console.log(err)
        rej(err)
      })
    } catch (err) {
      console.log(err)
      rej(err);
    }
  })
}
export function checkin(
  signature,
  user
) {
  console.log(  user,
    signature)
  return new Promise(async (res, rej) => {
    try{
      post('/api/airdrop/evm/punchIn', {
        chain_id: ChainIdMap[localStorage.getItem('kepler_chain')||'Arbitrum'],
        signature,
        user
      }).then(resp => {
        resp.data ? notification.success({
          message: 'Check in Success',
          description: 'you can check in in 24 hours later until you fininshed the task'
        }):notification.error({
          message: 'Check in Failed',
          description: 'you can check in in 24 hours later until you fininshed the task'
        })
        res(resp.data)
      }).catch(err =>{
        console.log(err)
        rej(err)
      })
    } catch (err) {
      console.log(err)
      rej(err);
    }
  })
}
export function bindtwitter(
  signature,
  twitter,
  user
) {
  return new Promise(async (res, rej) => {
    try{
      post('/api/airdrop/evm/bindTwitter', {
        chain_id: ChainIdMap[localStorage.getItem('kepler_chain')||'Arbitrum'],
        signature,
        twitter,
        user
      }).then(resp => {
        res(resp.data)
      }).catch(err =>{
        console.log(err)
        notification.error({
          message: 'Bind Failed',
          description: err.msg+'; One Twitter account cannot be bound multiple times'
        })
        rej(err)
      })
    } catch (err) {
      console.log(err)
      rej(err);
    }
  })
}

export function batchClaim(
  token_ids, max_amounts, amounts, signature
) { // list nft
  return new Promise(async (resp, rej) => {
    console.log(token_ids, max_amounts, amounts, signature)
    try{
      const provider = await createProviderController(chain).connect()
      const web3 = new Web3(provider)
      const accounts = await web3.eth.getAccounts();
      const address = accounts[0];
      new web3.eth.Contract(BoxAirdrop, getCurAddress().BoxAirdrop).methods.batchClaim(token_ids, max_amounts, amounts, signature)
      .estimateGas({from: address}).then(async(res)=>{
        console.log(res)
        let gas_price = await web3.eth.getGasPrice()*1.2/1000000000
        new web3.eth.Contract(BoxAirdrop, getCurAddress().BoxAirdrop).methods.batchClaim(token_ids, max_amounts, amounts, signature)
        .send({from: address, gas: res, gasPrice: Math.ceil(gas_price*1000000000)})
        .then((result) => {
          resp(result)
         notification.success({
          message: 'Transaction Success',
          description: <a  target="_blank" href={`${chainSymbolMap[store.getState().chain]().params.blockExplorerUrls[0]}tx/${result.transactionHash}`}>Go to browser to view</a>
        })
        })
        .catch((error) => {
          rej(error);
        });
      }).catch(err => {
        rej(err);
        console.log(err)
      })
      
    } catch (err) {
      rej(err);
    }
  })
}
export function batchClaimKeys(
  token_ids, amounts, signature
) { // list nft
  return new Promise(async (resp, rej) => {
    console.log(token_ids, amounts, signature)
    try{
      const provider = await createProviderController(chain).connect()
      const web3 = new Web3(provider)
      const accounts = await web3.eth.getAccounts();
      const address = accounts[0];
      new web3.eth.Contract(KeyAirdrop, getCurAddress().KeyAirdrop).methods.batchClaim(token_ids, amounts, signature)
      .estimateGas({from: address}).then(async(res)=>{
        console.log(res)
        let gas_price = await web3.eth.getGasPrice()*1.2/1000000000
        new web3.eth.Contract(KeyAirdrop, getCurAddress().KeyAirdrop).methods.batchClaim(token_ids, amounts, signature)
        .send({from: address, gas: res, gasPrice: Math.ceil(gas_price*1000000000)})
        .then((result) => {
          resp(result)
         notification.success({
          message: 'Transaction Success',
          description: <a  target="_blank" href={`${chainSymbolMap[store.getState().chain]().params.blockExplorerUrls[0]}tx/${result.transactionHash}`}>Go to browser to view</a>
        })
        })
        .catch((error) => {
          rej(error);
        });
      }).catch(err => {
        rej(err);
        console.log(err)
      })
      
    } catch (err) {
      rej(err);
    }
  })
}