import { Button, Tabs } from "antd"
import Modal from '../../../components/Base/Modal'
import { useEffect, useState } from "react"
import './index.scss'
import Claim from "./Claim"
import Task from "./Task"
import { get } from "../../../http"
import { ChainIdMap } from "../../../lib/util"
let {TabPane} = Tabs
export default ({tokenIds}) => {
    let [showClaim, setShowClaim] = useState(false)
    let [lastTime, setLasttime] = useState(0)
    useEffect(()=>{
        get('/api/airdrop/evm/config', {
            chain_id: ChainIdMap[localStorage.getItem('kepler_chain')||'Arbitrum']
        }).then(res => {
            setLasttime(62-Math.ceil((Date.now()/1000 - res.data.claim_start_time)/(24*60*60)))
        })
    }, [])
    return (
        <>
        <Button  className={"claim-btn my-button bge cf fwb fz-15 ta m-m-t-20 "+(lastTime < 0 ?'disabled':'')} onClick={() => {
            lastTime > 0 && setShowClaim(true)
        }}>
            <span className="fwb">
            Claim
            </span>
            
             ( <span className="days fz-14 fwb">{lastTime < 0 ?'Ended':lastTime+' Days Left'}</span> )
            {/* <img src={soon} alt="" className='soon' /> */}
        </Button>
        <Modal isVisible={showClaim} margin={20} onClose={_ => setShowClaim(false)} title={
            <div className="w100">
                Airdrop box claim
                    <span className="fz-12"> ( <span className="days fz-14 fwb">{lastTime < 0 ?'Ended':lastTime+' Days Left'}</span>  )</span>
            </div>
        }
        extraContent={
            <div>
                <a className="ce" target="_blank" href="https://presale-arbitrum.kepler.homes/">Presale</a>
                <span className="cb2 m-l-8 m-r-8">｜</span>
                <a target="_blank" href="https://passport-arbitrum.kepler.homes/">Whitelist</a>
            </div>
        }
        >
            <Task tokenIds={tokenIds}/>
        </Modal>
        </>
        
    )
}