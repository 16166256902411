import './Rules.scss'
import Air from './image/box/air.png'
import { Tabs } from 'antd';
import { useEffect, useState } from 'react'
import { boxList } from '../../lib/const'
import Activenumber from '../../components/Base/Activenumber';
const { TabPane } = Tabs;


const BoxItem = ({ info }) => {
    return <div className='box-item p-t-50'>
        <img src={Air} alt="" className='air' />
        <img src={info.cover} alt="" className='box-img' />
        <div className='fz-16 fwb cf w100 ta lh-20'>Scarcity ratio: <span className='ce'>{info.rate}% </span> (scarcity ratio)</div>
    </div>
}
const Overview = ({text}) => {
    let [activeIndex, setActiveIndex] = useState(0)
    return (
        <div className='flex flex-center flex-between overview'>
            <div>
                {
                    text
                }
            </div>

            <div className="rules-img flex flex-center">
                <BoxItem info={boxList[activeIndex]} />
                <div className='box-mini-wrap flex gap-12 flex-column'>
                    {
                        boxList.map((item, index) => {
                            return <img src={item.img} key={index} onMouseEnter={() => { setActiveIndex(index) }} />
                        })
                    }
                </div>
            </div>

        </div>
    )
}
const RulesText = () => {
    let [active, setActive] = useState('1')
    return (
        <div className='rules-text p-b-34 '>
            <div className='fz-64 fwb cf m-fz-32 m-lh-36'>
                Kepler AirDrop Box <br /> Incentive Rules
            </div>
            <div className="w100 cf ">
                <Tabs defaultActiveKey={'1'} onChange={e => { setActive(e) }} className='my-tab' moreIcon={''}>
                    <TabPane tab={<span className='rules-tab fz-18 active'>Overview</span>} key="1">
                        <Overview text={
                            <>
                                <div className="fz-32 cf fwb m-fz-24 m-lh-30 m-m-t-36 m-m-b-12">Kepler AirDropBox Overview</div>
                                <div className="fz-16 cf lh-24 text-inner">
                                    Kepler AirDropBox’s airdrop is aimed at Arbitrum ecological users, including bridgeworld-legions, Space-id, Smol-brains, Arbitrum Odyssey NF Holder, Token Hold: GMX, Excalibur, Magic, GNS, UniSwap, Sushi and other active interactive addresses, A total of 1,000,000 Kepler AirdropBoxes will be airdropped.

                                    <br />
                                    <br />
                                    <span className="ce">Including: ecological users take the initiative to receive rewards: 750,000; rewards for inviting qualified friends: 250,000;</span>
                                    <div className="fz-12 cf4 lh-24 m-t-46 m-m-t-1">
                                        *  This airdrop event is first come, first served while stocks last; <br/>
                                        *  KeplerHomes reserves the right to modify and interpret this AirdropBox airdrop.<br/>
                                        *  KeplerHomes officially reserves 8% of AirDropBox for subsequent marketing activities. 
                                    </div>
                                </div>
                            </>
                        } />
                    </TabPane>
                    <TabPane tab={<span className='rules-tab fz-18'>Odyssey Airdrop</span>} key="2">
                        <Overview text={<div className='fz-18 cf m-t-40 text-inner'>Odyssey Airdrop is for wallet addresses that have participated in Odyssey missions and SpaceID, Arbitrum mainstream NFT Holder (specifically: bridgeworld-legions, space-id, smol-brains, Arbitrum Odyssey NFT, The Beacon, GMX Blueberry Club, government-toucans)
                            Wallet addresses that have recently interacted with contracts such as GMX, GDX, Camelot, Magic, GNS, UniSwap, and Sushi. (As of 2023.3.24)</div>} />

                    </TabPane>
                    <TabPane tab={<span className='rules-tab fz-18'>Blur AirDrop</span>} key="3">
                        <Overview text={<div className="fz-18 cf text-inner">
                            Kepler AirDropBox is for the addresses of users who have interacted with Blur in the last 180 days; KeplerHome maps active addresses that have interacted with Blur.io on the Ethereum network to the Arbitrum One network
                        </div>} />

                    </TabPane>
                    <TabPane tab={<span className='rules-tab fz-18'>Claim PFP NFT</span>} key="4">
                        <Overview text={<div className="cf fz-18 text-inner">Kepler AirDropBox will present 20,000 ECR-1155 Kepler PFP NFTs to Arbitrum & Odyssey, SpaceID and Blur users, 10,000 male and female game characters each. First come first served, while stocks last.</div>} />

                    </TabPane>
                </Tabs>




            </div>
        </div>
    )
}
export default () => {
    
    return (
        <div className='rules w100'>
            <div className="rules-inner">
                <div className="rules-content flex m-t-70 flex-end">
                    <RulesText />
                </div>
            </div>

        </div>
    )
}