import { Button, Popover, Skeleton, Timeline, Tooltip } from "antd"
import refreshicon from '../image/refresh.svg'
import refreshe from '../image/refreshe.svg'
import refreshg from '../image/refreshg.svg'
import refreshb from '../image/refreshb.svg'
import question from '../image/questiong.svg'
import check from '../image/check.svg'
import close from '../../../assets/images/base/closegrey.svg'
import dui from '../image/dui.svg'
import right from '../image/right.svg'
import './index.scss'
import {connect} from 'react-redux'
import { boxList, keyList } from "../../../lib/const"
import { useCallback, useEffect, useState } from "react"
import { ChainIdMap, findAddressByName } from "../../../lib/util"
import { get, post } from "../../../http"
import { batchClaim, batchClaimKeys, bindtwitter, checkin, queryClaimRecords, queryClaimRecordsKeys } from "../../../contract/methods/boxairdrop"
import { balanceOfNFT, isApprovedForAll, setApprovalForAll, sign } from "../../../contract/methods"
import * as hello from 'hellojs'
import notification from "../../../components/notification"
const Usertype = ({ type, onRefresh, loading}) => {
    return (
        <span className={"user-type flex cf m-r-12 "+(type==2?'green':type==3?'orange':'')}>
           {type==2?'Whitelist':type==3?'Presale':'Ordinary'} User
           {
            loading?<Skeleton.Button active={true} size='small' shape='default' block={false} />:<img src={type==2?refreshg:type==3?refreshe:refreshb} alt="" className="m-l-3 pointer" onClick={onRefresh} />
           }
            
        </span>   
    )
}
const Record = ({
    total,
    complete
}) => {
    return (
        <div className="record flex flex-center gap-6 m-t-17 flex-wrap">
           {
            (()=>{
                let list = []
                for(let i=0;i<total;i++) {
                    list.push(
                        <div key={i} className={"record-item flex flex-column flex-center p-t-8  p-b-8 "+(complete > i ? 'checked':'')}>
                            <span className="days-num m-b-8 fz-14 fwb">
                                + {i+1}
                            </span>
                            {
                                complete > i ? <img src={check} className="check-icon"></img>:<span className="dot">···</span>
                            }
                        </div>
                    )
                }
                return list
            })()
           }
        </div>
    )
}
const Box = ({ box, num }) => {
    return (
        <div className='box-item-wrap'>
            <div className='box-item'>
                <img src={box} alt="" className='box-img' />
            </div>
            <div className="cf fwb ta fz-10 num ">{num}</div>
            {/* <div className="cb fwb ta fz-11 name m-t-5">{name}</div> */}
        </div>

    )
}
const BoxA = ({ box, name, num }) => {
    return (
        <div className='box-item-wrap-inner'>
            <div className='box-item'>
                <img src={box} alt="" className='box-img' />
            </div>
            <div className="cf fwb ta fz-14 num p-l-13 p-r-13 p-t-2">{num>0?num:0}</div>
            <div className="cb fwb ta fz-11 name m-t-5">{name}</div>
        </div>

    )
}

const KeyItem = ({ box, name, num }) => {

// balanceOfNFT
    return (
        <div className='box-item-wrap-inner'>
            <div className='box-item'>
                <img src={box} alt="" className='box-img' />
            </div>
            <div className="cf fwb ta fz-14 num p-l-13 p-r-13 p-t-2">{num}</div>
            <div className="cb fwb ta fz-11 name m-t-5">{name}</div>
        </div>

    )
}
export default connect(
    (state, props) => {
      return {...state, ...props}
    }
  )( (props)=> {
    const userDays = {
        1: 10,
        2: 5,
        3:3
    }
    let [user_type, setUserType] = useState(1) //1普通用户 2白名单用户 3presale用户
    let [userInfo, setUserInfo] = useState({}) 
    let [punch_count, setPunchCount] = useState(0)
    let [refresh, setRefresh] = useState(0)
    let [loading, setLoading] = useState(false)
    let [tloading, setTLoading] = useState(false)
    let [interval, setIntervalTime] = useState(300) //5分钟
    let [lastTime, setLasttime] = useState(0) // 上一次打卡时间
    let [twitterId, setTwitterId] = useState('')
    let [twitterUserInfo, setTwitterInfo] = useState({})
    let [balanceKeys, setBalanceKeys] = useState([0,0,0,0,0])
    let [record, setRecord] = useState([0,0,0,0,0])
    let [recordkey, setRecordKey] = useState([0,0,0,0,0])
    let [numList, setNumList] = useState([0,0,0,0,0])
    let [claimLoading, setClaimLoading] = useState(false)
    let [userLoading, setUserLoading] = useState(false)
    let [keyLoading, setKeyLoading] = useState(false)
    let [isApprove, setIsApprove] = useState(false)
    const calNum = (v) => {
        console.log(v)
        if (typeof (v) == 'undefined') {
            return 0
        } else {
            return v * 1
        }
    }
    const toApprove = () => {
        setClaimLoading(true)
        setApprovalForAll(findAddressByName('KeyAirdropNFT'), findAddressByName('BoxAirdrop')).then(res => {
            setIsApprove(true)
        }).finally(() => {
            setClaimLoading(false)
        })
    }
    const toCheckIn = useCallback(async () => {
        setLoading(true)
        try {
            let signature = await sign('Kepler Box Airdrop, Punch in')
            checkin(signature, props.account).then(res => {
                
            }).catch(err => {
            }).finally(res => {
                setLoading(false)
                setRefresh(refresh+1)
            })
        } catch (err) {
            setLoading(false)
        }
    }, [props.account]) 
    const bindTwitter = async (name) => {
        try {
            let signature = await sign(name)
            bindtwitter(signature, name, props.account).then(res => {
                
            }).catch(err => {
            }).finally(res => {
            setTLoading(false)
                setRefresh(refresh+1)
            })
        } catch (err) {
            setLoading(false)
        }
    }
    const toVerify = () => {
        setTLoading(true)
        hello.init({'twitter': 'djLOXEmHnG6UScEf2Aq6ktUXq'})
        hello('twitter').login().then(res => {
            console.log(res)
            setTwitterInfo(res.authResponse)
            bindTwitter(res.authResponse.screen_name)
        }, err => {
            console.log(err)
            setTLoading(false)
        })
    }
    const toClaim = () => {
        let tokens = []
        numList.map((item, index) => {
            if(item >0) {
                tokens.push(index*1+1)
            }
        })
        setClaimLoading(true)
        post('/api/airdrop/evm/box/claimParams', {
            amounts: numList.filter(item => item>0).join(','),
            chain_id: ChainIdMap[localStorage.getItem('kepler_chain')||'Arbitrum'],
            contract: findAddressByName('BoxAirdrop'),
            token_ids: tokens.join(','),
            user: props.account
        }).then(res => {
            console.log(res.data.signature)
            batchClaim(res.data.token_ids, res.data.max_amounts, res.data.amounts, res.data.signature).then(res => {
                setClaimLoading(false)
                setRefresh(refresh+1)
            }).catch(err => {
                setClaimLoading(false)
                console.log(err)
                notification.error({
                    message: 'Claim Failed'
                })
            })
        })
    }

    const toClaimkey = () => {
        setKeyLoading(true)
        post('/api/airdrop/evm/key/claimParams', {
            chain_id: ChainIdMap[localStorage.getItem('kepler_chain')||'Arbitrum'],
            contract: findAddressByName('KeyAirdrop'),
            user: props.account
        }).then(res => {
            console.log(res.data.signature)
            batchClaimKeys(res.data.token_ids, res.data.amounts, res.data.signature).then(res => {
                setKeyLoading(false)
                setRefresh(refresh+1)
            }).catch(err => {
                setKeyLoading(false)
                console.log(err)
                notification.error({
                    message: 'Claim Failed'
                })
            })
        })
    }
    useEffect(()=>{
        let num_List = []
        balanceKeys.map((item, index) => {
            let num = Math.min(item, props.tokenIds[boxList[index].name?.toLowerCase() + '_box_amount'])
            num_List.push(num-record[index] >0?num-record[index]:0)
        })
        setNumList(num_List)
    }, [props.tokenIds, balanceKeys, record])
    useEffect(async()=>{
        if(props.account) {
            let p = []
            keyList.map(item => {
              p.push(balanceOfNFT(findAddressByName('KeyAirdropNFT'), item.tokenId))
            })
            Promise.all(p).then(res => {
              setBalanceKeys(res)
            })
            let claimRecord = await queryClaimRecords(props.account)
            let claimRecordKeys = await queryClaimRecordsKeys(props.account)
            console.log(claimRecord)
            setRecord(claimRecord)
            setRecordKey(claimRecordKeys)
        } else {
            setBalanceKeys([0,0,0,0,0])
        }
          
    }, [props.account, refresh])
    useEffect(async()=> {
        if(props.account) {
        let isapprove = await isApprovedForAll(findAddressByName('KeyAirdropNFT'), findAddressByName('BoxAirdrop'))
        setIsApprove(isapprove)
        }
    }, [props.account])
    useEffect(()=>{
        get('/api/airdrop/evm/config', {
            chain_id: ChainIdMap[localStorage.getItem('kepler_chain')||'Arbitrum']
        }).then(res => {
            setIntervalTime(res.data.punch_in_interval)
            setTwitterId(res.data.retwitter_id)
        })
        if(props.account) {
            setUserLoading(true)
            get('/api/airdrop/evm/user', {
                chain_id: ChainIdMap[localStorage.getItem('kepler_chain')||'Arbitrum'],
                address: props.account
            }).then(res => {
                setUserType(res.data.is_presale_user?3:res.data.is_passport_user?2:1)
                setUserInfo(res.data)
                setTwitterInfo({
                    screen_name: res.data.twitter
                })
                setPunchCount(res.data.punch_in_count)
            }).finally(res =>{
                setUserLoading(false)
            })
        } else {
            setTwitterInfo({})
        }
        
    }, [props.account, refresh])
    return (
        <div className="task-box w100">
            <Timeline>
                <Timeline.Item dot={<div className="order">1</div>}>
                    <div className="task-item">
                        <div className="task-title fz-14 fwb flex flex-center flex-wrap">
                            <Usertype type={user_type} onRefresh={()=>setRefresh(refresh+1)} loading={userLoading}/>
                            Check in for a total of {userDays[user_type]} days
                            <Popover placement="bottom"  content={
                               <div className="rules-inner">
                                <span className="fz-13 fwb">Rules:</span>
                            <span className="flex m-t-15">
                                <span className="left-order">
                                    A
                                </span>
                                <span>
                                    Users who have participated in the presale need to sign in for a total of 3 days, no need for 3 consecutive days,A total of 3 days is enough.
                                </span>
                            </span>
                            <span className="flex m-t-15">
                                <span className="left-order">
                                    B
                                </span>
                                <span>
                                    Whitelist users have signed in for a total of 5 days.
                                </span>
                            </span>
                            <span className="flex m-t-15">

                                <span className="left-order">
                                    C
                                </span>
                                <span>
                                    other users have signed in for 10 days in total
                                </span>
                            </span>
                            </div>
                            }>
                            <img src={question} alt="" className="m-l-8"/>
                            </Popover>
                            {
                                punch_count < userDays[user_type] && <Button loading={loading} className="black-btn sign-btn" onClick={toCheckIn}>Check-in</Button>
                            }
                            
                        </div>
                        <div className="rules-box flex flex-column m-t-15">
                            <span className={"status "+(punch_count >= userDays[user_type] ? 'completed':'uncomplete')}>
                             {
                               punch_count >= userDays[user_type] ? 'Completed':'Uncompleted'
                             }
                            </span>
                            <span className="fz-12"> <span className="days fz-14 fwb">{userDays[user_type]-punch_count<0?0:userDays[user_type]-punch_count}</span> Days Remainning</span>
                            <Record total={userDays[user_type]} complete={punch_count}/>
                        </div>

                    </div>

                </Timeline.Item>
                <Timeline.Item dot={<div className="order">2</div>}>
                    <div className="task-item ">
                        <div className="task-title fz-14 fwb flex flex-center flex-between">
                            <span className="flex flex-center">
                            Retweet Twitter

                            <Popover placement="bottom"  content={
                               <div className="rules-inner">
                                <span className="fz-13">The data will be synchronized within 5 minutes after you retweet this twitter</span>
                            </div>
                            }>
                            <img src={question} alt="" className="m-l-8"/>
                            </Popover>
                            </span>
                            <span className="flex flex-center">
                            {
                                twitterUserInfo.screen_name ? <div className="twitter-info flex">
                                    {twitterUserInfo.screen_name}
                                    <img src={close} alt="close" className="pointer" onClick={()=>setTwitterInfo({})} />
                                </div>:<Button className="black-btn sign-btn" onClick={toVerify} loading={tloading}>Verify</Button>
                            }
                            {
                                userLoading?<Skeleton.Button active={true} size='small' shape='default' block={false} />:<img src={refreshicon} alt="" className="m-l-5 refresh pointer" onClick={()=>setRefresh(refresh+1)}/>
                            }
                            
                            </span>
                            
                        </div>
                        <div className="rules-box flex flex-column m-t-15">
                            {/* <span className={"status "+(userInfo.is_retweeted ? 'completed':'uncomplete')}>
                             {
                              userInfo.is_retweeted ? 'Completed':'Uncompleted'
                             }
                            </span> */}
                            <span className="fz-13 fwb">Rules:
                                retweet the <a className="underline tw-link" href={`https://twitter.com/KeplerHomes/status/${twitterId}`} target="_blank">this twitter</a>
                            </span>
                        </div>
                    </div>
                </Timeline.Item>

                <Timeline.Item dot={<div className="order">3</div>}>
                    <div className="task-item ">
                        <div className="task-title fz-14 fwb flex flex-center">
                        Collect matching keys
                        <Popover placement="bottom"  content={
                               <div className="rules-inner">
                                <span className="fz-13 fwb">Rules:</span>
                            <span className="flex m-t-15">
                                {/* <span className="left-order">
                                    A
                                </span> */}
                                <span> In order to claim a Airdropbox of a specific color, you must possess the corresponding color key. Only with the matching key can you unlock and claim the box.
                                </span>
                            </span>
                            </div>
                            }>
                            <img src={question} alt="" className="m-l-8"/>
                            </Popover>
                            <span className="btns">
                            <Button className="black-btn sign-btn m-r-5" loading={keyLoading} onClick={toClaimkey} disabled={recordkey[0]>0}>{
                                recordkey[0]>0?'Keys Claimed': 'Claim Keys'
                            }</Button>
                            <a href="https://market.kepler.homes/AirdropKey" target="_blank" className=" sign-btn">
                            <Button className="black-btn  sign-btn">Marketplace</Button>
                            </a>
                            </span>
                            
                            
                        </div>
                        <div className="rules-box flex m-t-15 flex-wrap gap-8">
                            <span className={"status "+(balanceKeys.some(item=>item>0) ? 'completed':'uncomplete')}>
                             {
                               balanceKeys.some(item=>item>0) ? 'Completed':'Uncompleted'
                             }
                            </span>
                            <Popover placement="bottom"  content={

            <div className="assets p-15">
            <div className="fz-14 fwb">My AirDropBox Not Claimed</div>
            <div className="flex flex-center flex-between p-t-10 gap-10">
            {
                boxList.map((item, index) => {
                    return <BoxA box={item.img} num={props.tokenIds[item.name?.toLowerCase() + '_box_amount']-record[index] || 0} name={item.name} key={index} />
                })
            }
            </div>
            
        </div>
                            }>
                            <div className="box-inner flex flex-center flex-between pointer gap-20 p-l-12 p-r-12">
                                <span>My Boxes not claimed ({
                                     (()=>{
                                        let total = 0
                                        boxList.map((item, index) => {
                                            total += (calNum(props.tokenIds[item.name.toLowerCase() + '_box_amount'])-record[index])
                                        })
                                        return total >0?total:0
                                     })()
                                    
                                    })</span>
                                <img src={right} alt="" />
                            </div>
                            </Popover>
                            <Popover placement="bottom"  content={

<div className="assets p-15">
<div className="fz-14 fwb">My Keys</div>
<div className="flex flex-center flex-between p-t-10 gap-10">
{
    keyList.map((item, index) => {
        return <KeyItem box={item.img} num={balanceKeys[index]} name={item.name} key={index} />
    })
}
</div>

</div>
                }>
                            <div className="box-inner flex flex-center flex-between pointer gap-20  p-l-12 p-r-12">
                                <span>My Keys ({
                                     (()=>{
                                        let total = 0
                                        balanceKeys.map((item, index) => {
                                            total += balanceKeys[index]*1
                                        })
                                        return total
                                     })()
                                    
                                    })</span>
                                <img src={right} alt="" />
                            </div>
                            </Popover>
                            
                        </div>
                    </div>
                </Timeline.Item>

                <Timeline.Item dot={<div className="order flex flex-center flex-middle"><img src={dui} alt="" /></div>}>
                    <div className="task-item ">
                        <div className="task-title fz-14 fwb flex flex-center">
                        After completed the tasks you can claim 
                        
                        <Popover placement="bottom"  content={

<div className="assets p-15">
<div className="fz-14 fwb">The Boxed You've Claimed</div>
<div className="flex flex-center flex-between p-t-10 gap-10">
{
    boxList.map((item, index) => {
        return <BoxA box={item.img} num={record[index] || 0} name={item.name} key={index} />
    })
}
</div>
<div className="fz-14 fwb m-t-20">The Keys You've Staked</div>
<div className="flex flex-center flex-between p-t-10 gap-10">
{
    keyList.map((item, index) => {
        return <KeyItem box={item.img} num={record[index] || 0} name={item.name} key={index} />
    })
}
</div>

</div>
                }><span className="underline ce m-l-10 pointer fz-12">Claim Record</span>
                </Popover>
                        </div>
                        <div className="flex flex-center gap-20 p-t-24">
                {
                    boxList.map((item, index) => {
                        return (<Box box={item.img} num={numList[index]} name={item.name} key={index} />) 
                    })
                }
                </div>
                    </div>
                </Timeline.Item>
            </Timeline>
            <Button className="claim-btn fz-18 fwb my-button bge cf w100"
            onClick={isApprove?toClaim:toApprove}
            loading={claimLoading}
            disabled={
                !(punch_count >= userDays[user_type] && numList.some(item => item>0))
            }
            >
                     {
                        isApprove ?'Claim':'Approve Your Keys -->  Claim'
                    }
                </Button>
                <div className="ta cb6 m-t-10 p-b-10 flex flex-column">
                You can only claim after completing the task on the top
                <a className="fz-14 ce ta underline" href="https://market.kepler.homes/mynft" target="_blank">Check My Boxes</a>
                </div>
        </div>
    )
})