import { Button, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import Activenumber from '../../components/Base/Activenumber'
import MyModal from '../../components/Base/Modal'
import { claimAvatar, queryBatchIdMintAmounts, queryGenderBalances, queryUserTokenId } from '../../contract/methods/avatarairdrop'
import { get } from '../../http'
import { ChainIdMap, findAddressByName, showConnectWallet } from '../../lib/util'
import './Claim.scss'
import baby from './image/baby.png'
import femaleactive from './image/femaleactive.png'
import female from './image/female.png'
import maleactive from './image/maleactive.png'
import soon from '../../assets/images/arb/soon.svg'
import male from './image/male.png'
import openSea from '../../assets/images/newairdrop/opensea.png'
import Element from '../../assets/images/newairdrop/element.png'
import Market from '../../assets/images/newairdrop/market.jpg'
import notification from '../../components/notification'
export default (props) => {
    let [tokenId, setTokenId] = useState('') 
    let [gender, setGender] = useState(0) //头像性别,0:女,1:男
    let [loading, setLoading] = useState(false)
    let [refresh, setRefresh] = useState(0)
    let [metaData, setMetaData] = useState({})
    let [showMeta, setShowMeta] = useState(false)
    let [isDisabled, setDisabled] = useState(false)
    let [bal, setBal] = useState({})
    const toTip = () => {
      notification.error({
         message: 'Claim Failed',
         description: 'This batch has minted out,please waiting for next batch.'
      })
   }
    const toClaim = async() => {
       setLoading(true)
     let {data: {signature, batch_id, batch_supply}} = await get('/api/airdrop/evm/avatar/drawParams', {
        user: props.account,
        chain_id: ChainIdMap['Arbitrum'],
        contract: findAddressByName('AvatarAirdrop'),
        gender
     }).catch(err => {
      notification.error({
         message: 'Claim Failed',
         description: 'You may not be qualified,Please contact the administrator'
      })
      setLoading(false)
     })
     queryBatchIdMintAmounts().then(res => {
      console.log(res)
      if(!res.amounts[batch_id-1] ||res.amounts[batch_id-1]*1 < batch_supply*1) {

         claimAvatar(batch_id, batch_supply, gender, signature).then(res => {
            setRefresh(refresh+1)
         }).finally(res => {
            setLoading(false)
         })
      } else {
         notification.error({
            message: 'Claim Failed',
            description: 'This batch has minted out,please waiting for next batch.'
         })
         setLoading(false)
      }
     })
    }
    const getConfig = async() => {
    let {data: {batch_id, batch_supply}} = await get('/api/airdrop/evm/avatar/drawParams', {
       user: props.account,
       chain_id: ChainIdMap['Arbitrum'],
       contract: findAddressByName('AvatarAirdrop'),
       gender
    })
    console.log('//////////////')
    console.log(batch_id, batch_supply)
    queryBatchIdMintAmounts().then(res => {
     if(!res.amounts[batch_id-1] ||res.amounts[batch_id-1]*1 < batch_supply*1) {
      setDisabled(false)
     } else {
      setDisabled(true)
     }
    })
   }
    useEffect(async () => {
       //判断是否mint过
       if(props.account) {
        let id = await queryUserTokenId(props.account)
        console.log(id)
        setTokenId(id)
        getConfig()
        if(!id) {
            return
        }
        let data = await get(`/api/airdrop/evm/avatar/metadata/${ChainIdMap['Arbitrum']}/${id}`)
        setMetaData(data)
       } else {
        setTokenId('')
       }
    }, [props.account, refresh])
    useEffect(async()=>{
       let balance = await  queryGenderBalances()
       setBal(balance)
    }, [refresh])
    return <div className='claim flex flex-center '>
         <img src={baby} alt="" className='baby' />
         <div className="claim-right m-l-110">
             <div className="fz-14 cf fwb">Please Choice the appropriate character gender PFP NFT</div>
             <div className="female-male flex flex-center m-t-40">
                <div className={"img-wrap " + (!gender ? 'active':'')} onClick={()=>setGender(0)}>
                    <img src={gender ? female:femaleactive} alt="" />
                    <div className="gender-name fz-14 ta cf">Female</div>
                    <div className="cornor left"></div>
                    <div className="cornor right"></div>
                    <div className="cornor top"></div>
                    <div className="cornor bottom"></div>
                </div>
                <div className={"img-wrap m-l-25 " + (gender ? 'active':'')} onClick={()=>setGender(1)}>
                    <img src={gender ? maleactive:male} alt=""/>
                    <div className="gender-name fz-14 ta cf">Male</div>
                    <div className="cornor left"></div>
                    <div className="cornor right"></div>
                    <div className="cornor top"></div>
                    <div className="cornor bottom"></div>
                </div>
                
             </div>
             <div className="btns flex flex-center m-t-48">
                 <div className="flex flex-column ">
                    <div className='cf fz-20 fwb'>Claimed / Total Supply</div>
                    <div className='ce fz-26 fwb'>
                     <Activenumber value={20000-bal.femaleBalance*1-bal.maleBalance*1} />
                     /20,000
                     </div>
                 </div>
                 {
                        tokenId <=0 && props.account ? (
                           isDisabled ? 
                           
                              <Button className="claim-btn my-button ce fwb fz-15 m-l-48 disabled" onClick={toTip}>
                                    Claim<img src={soon} alt="" className='soon' />
                              </Button>
                           :
                           <Button className="claim-btn my-button m-l-48" onClick={toClaim} loading={loading}>Claim</Button>
                        )
                        :
                         props.account ? <Button className="claim-btn my-button m-l-48" onClick={()=>setShowMeta(true)}>View My Avatar NFT</Button>:
                        // !props.account ?
                        <Button className="claim-btn my-button m-l-48" onClick={showConnectWallet}>Connect Wallet</Button>
                        // <Button className="claim-btn my-button m-l-48" disabled>Not in whitelist</Button>
                 }
                 
             </div>

             <div className="fz-12 fwb cf4 ta m-t-24">
             Successfully participated the <a href="https://presale-arb.kepler.homes" target="_blank"><u className='cf'>Kepler Presale</u></a> can increase the probability of <br/>unpacking high attribute quality by 10-30%.
                            </div>
         </div>
         <MyModal title="My Avatar" isVisible={showMeta} onClose={()=>setShowMeta(false)}>
             <img src={metaData.image} alt="" />
             <div className="fz-16 fwb c0 ta w100 m-t-20">{metaData.name}</div>
             <div className="fz-16 fwb c0 ta w100 m-t-20 flex flex-center flex-middle gap-20">
                  <a href="https://market.kepler.homes/KeplerProtagonist" target="_blank" className='fz-12 c006 flex flex-center'>
                     <img src={Market} alt="" className='market-icon' /> Kepler Market
                  </a>
                  <a href="https://element.market/collections/Kepler_PFP_Arb" target="_blank" className='fz-12 c006 flex flex-center'>
                     <img src={Element} alt="" className='market-icon' /> Element
                  </a>
                  <a href="https://opensea.io/collection/kepler-pfp-arb" target="_blank" className='fz-12 c006 flex flex-center'>
                     <img src={openSea} alt="" className='market-icon' /> OpenSea
                  </a>
             </div>
         </MyModal>
    </div>
}