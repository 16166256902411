export default {
    "ARB": "0x912ce59144191c1204e64559fe8253a0e49e6548",
    "USDT": "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
    "USDC": "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
    "KEPL": "0x725bd64eb3bee008ee526ce7f32cb67214e71103",
    "PreKEPL": "0x1653c024efe4fb3d37d4686c253eb37f6e2dcc79",
    "veKEPL": "0x07ca21cd429a1ce28dfde9fd9afba5cf09b3f6ff",
    "ChainlinkOracle": "0x19d86d6e159c1354916ab9c6f81e2033fbd0968c",
    "BoxAirdropNFT": "0x03e3fa5c54ee6ae1a7a2843639bf179bc6f7b6bd",
    "AvatarAirdropNFT": "0xe406ae51389acadf87964ce0073b9724cc25d25c",
    "PresaleBak": "0x573e96b7a4c977025b513996d3592155d5fca11d",
    "Presale": "0xf13245c3418afda822c687a3ce37a75b7c3d96dc",
    "Marketplace": "0x5384f6afa44056234dc8f790dfd2c2b44a993ca9",
    "KeyAirdropNFT": "0x33bcaeb9392009b7fe90c73b00fa66bbaa69ad7c",
    "BoxAirdrop": "0xf2afe72107e5a6eba5a46fd42f99bc81b4b72eb0",
    "KeyAirdrop": "0x54c37456d41f949d30a05541cb9fb447f3a131b9",
    "AvatarAirdrop": "0x3dc7117d47217abd4de21556c13c1b311a4d642b"
}