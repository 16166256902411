import Web3 from 'web3'
import {bep20ABI} from '../abi/bep20'
import AvatarAirdrop from '../testnet/AvatarAirdrop'
import BoxAirdrop from '../testnet/BoxAirdrop'
import {getCurAddress}  from '../testnet/address'
import notification from '../../components/notification'
import getNetworkData, { chainSymbolMap } from '../../wallet/helper/getNetworkData';
import { createProviderController } from '../../wallet/web3/createProviderController'
import store from '../../store'
const chain = localStorage.getItem('kepler_chain') || 'Arbitrum'

const httpProviderURL = 'https://data-seed-prebsc-1-s2.binance.org:8545/'
console.log(httpProviderURL)
// const provider = await createProviderController(chain).connect()
export const web3 = new Web3(new Web3.providers.HttpProvider(httpProviderURL))
// if(!web3) {
    
// }

function createWeb3(chain) {
  let httpProviderURL
  httpProviderURL = getNetworkData.getArbitrumNetwork().httpProviderURL
  
  return new Web3(new Web3.providers.HttpProvider(httpProviderURL))
}

function createCurWeb3() {
  const chain = store.getState().chain
  return createWeb3(chain)
}

// / 将string转成bytes32
const stringToBytes32 = (s) => {
  let result = web3.utils.fromAscii(s);
  while (result.length < 66) {
      result = result + "0";
  }
  return result;
}


export function balanceOf (contractAddress, address) {
  const web3 = createCurWeb3()
  return new web3.eth.Contract(bep20ABI, contractAddress).methods.balanceOf(address).call()
}




// Airdrop
export function queryUserTokenId(user) {
  const web3 = createCurWeb3()
  return new web3.eth.Contract(AvatarAirdrop, getCurAddress()[`AvatarAirdrop`]).methods.queryUserTokenId(user).call()
}
export function queryBatchIdMintAmounts() {
  const web3 = createCurWeb3()
  return new web3.eth.Contract(AvatarAirdrop, getCurAddress()[`AvatarAirdrop`]).methods.queryBatchIdMintAmounts().call()
}
export function queryGenderBalances() {
  const web3 = createCurWeb3()
  return new web3.eth.Contract(AvatarAirdrop, getCurAddress()[`AvatarAirdrop`]).methods.queryGenderBalances().call()
}

export function claimAvatar(
  batchId,
  batch_supply,
  gender,
  signature
) {
  console.log(gender,
    signature)
  return new Promise(async (resp, rej) => {
    try{
      const provider = await createProviderController(store.getState().chain).connect()
      const web3 = new Web3(provider)
      const accounts = await web3.eth.getAccounts();
      const address = accounts[0];
      new web3.eth.Contract(AvatarAirdrop, getCurAddress()[`AvatarAirdrop`]).methods.draw(
        batchId,
        batch_supply,
        gender,
        signature
      )
      .estimateGas({from: address}).then(async(res)=>{
        console.log(res)
        let gas_price = await web3.eth.getGasPrice()*1.2/1000000000
        new web3.eth.Contract(AvatarAirdrop, getCurAddress()[`AvatarAirdrop`]).methods.draw(
          batchId,
          batch_supply,
          gender,
          signature
        )
        .send({from: address, gas: res, gasPrice: Math.ceil(gas_price*1000000000)})
        .then((result) => {
          resp(result)
         notification.success({
          message: 'Transaction Success',
          description: <a  target="_blank" href={`${chainSymbolMap[store.getState().chain]().params.blockExplorerUrls[0]}tx/${result.transactionHash}`}>Go to browser to view</a>
        })
        })
        .catch((error) => {
          rej(error);
        });
      }).catch(err => {
        rej(err);
        console.log(err)
      })
    } catch (err) {
      rej(err);
    }
  })
}