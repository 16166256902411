


import box1 from '../pages/components/image/box/1.svg'
import box2 from '../pages/components/image/box/2.svg'
import box3 from '../pages/components/image/box/3.svg'
import box4 from '../pages/components/image/box/4.svg'
import box5 from '../pages/components/image/box/5.svg'
import boxBig1 from '../pages/components/image/box/box1.png'
import boxBig2 from '../pages/components/image/box/box2.png'
import boxBig3 from '../pages/components/image/box/box3.png'
import boxBig4 from '../pages/components/image/box/box4.png'
import boxBig5 from '../pages/components/image/box/box5.png'
import key from '../pages/components/image/box/key.svg'
import key1 from '../assets/images/keys/1.png'
import key2 from '../assets/images/keys/2.png'
import key3 from '../assets/images/keys/3.png'
import key4 from '../assets/images/keys/4.png'
import key5 from '../assets/images/keys/5.png'


export const boxList = [
        {
            name: 'Grey',
            img: box1,
            cover: boxBig1,
            rate: '66.66'
        }, {
            name: 'Orange',
            img: box2,
            cover: boxBig2,
            rate: '22.22'
        }, {
            name: 'Blue',
            img: box3,
            cover: boxBig3,
            rate: '9.99'
        }, {
            name: 'Purple',
            img: box4,
            cover: boxBig4,
            rate: '1.11'
        }, {
            name: 'Emerald',
            img: box5,
            cover: boxBig5,
            rate: '0.02'
        }
    ]


export const keyList = [
    {
        name: 'Grey',
        img: key1,
        tokenId: 1
    }, {
        name: 'Orange',
        img: key2,
        tokenId: 2
    }, {
        name: 'Blue',
        img: key3,
        tokenId: 3
    }, {
        name: 'Purple',
        tokenId: 4,
        img: key4
    }, {
        name: 'Emerald',
        img: key5,
        tokenId: 5
    }
]