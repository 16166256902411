import Airnav from "../components/layouts/Airnav"
import Mintfooter from "../components/layouts/Mintfooter"
import './Airdrop.scss'
import { Button, Carousel } from "antd"
import { Link } from "react-router-dom"
import { tokenList } from "../lib/airdrop"
import { queryVariables, queryClaimers } from '../contract/methods/avatarairdrop'
import { useEffect, useState } from "react"
import { ChainIdMap, addPoint, findNameByAddress, fromUnit, numFormat, toUnit, toWei } from "../lib/util"
import { toFixed } from "accounting"
import { Tabs } from 'antd';
import Odysey from "./components/Odyssey"
import Rules from "./components/Rules"
import Progress from "./components/Progress"
import Rewards from "./components/Rewards"
import soon from '../assets/images/arb/soon.svg'
import hot from '../assets/images/arb/hot.svg'
import {connect} from 'react-redux'
import Claim from "./components/Claim"
import Countdown from "../components/Base/Countdown"
import { get } from "../http"
import Activenumber from "../components/Base/Activenumber"

const { TabPane } = Tabs;
export default connect(
    (state, props) => {
      return {...state, ...props}
    }
  )( (props)=> {
    let [activeKey, setActive] = useState('1')
    let [code, setCode] = useState('')
    let [totalAirdrop, setTotalAirdrop] = useState(0)
    let [loading, setLoading] = useState(false)
    useEffect(()=> {
        setTotalAirdrop(937318)
    }, [])
    useEffect(()=>{
        console.log(activeKey)
        if(activeKey == "5") {
            setActive("1")
        }
    }, [activeKey])
    useEffect(async()=> {
        if(props.account) {
            try {
                setLoading(true)
                let {data:code} = await get('/api/airdrop/evm/code', {
                    address: props.account,
                    chainId: ChainIdMap[localStorage.getItem('kepler_chain') || 'Arbitrum']
                })
                setLoading(false)
                setCode(code)
            } catch {
                setLoading(false)
                setCode('')
            }
        } else {
            setCode('')
        }
    }, [props.account])
    return (
        <div className="airdrop w100 m-t-0">
            <img src={require('../assets/images/newairdrop/topbg.svg').default} alt="" className="topbg" />

            <img src={require('../assets/images/newairdrop/bottombg.svg').default} alt="" className="bottombg" />
            <Airnav />
            <div className="main-tab-area w100 p-b-0">
                {/* <div className="flex flex-center flex-middle">
                    <span className="cf fwb fz-16 p-r-10">Start In:</span>
                    <Countdown deadline={new Date('2023-05-29').getTime()}/>
                </div> */}

               
                
                <div className="flex flex-middle w100">
                    <Tabs defaultActiveKey={'1'} onChange={e => {setActive(e)}} className='my-tab' moreIcon={''}>
                        <TabPane tab='Airdrop' key="1"></TabPane>
                        {/* <TabPane tab='Blur AirDrop' key="2"></TabPane> */}
                        <TabPane tab={<span className="c06 airtab-item">Claim PFP NFT </span>} key="3"></TabPane>
                        {/* <TabPane tab={<span className="c06 airtab-item">Claim PFP NFT </span>} key="3"></TabPane> */}
                        <TabPane disabled tab={<a className="airtab-item" style={{color:'rgba(140, 250, 80, 1)'}} href="https://presale-arb.kepler.homes" target="_blank">Presale <img src={hot} alt="" className="soon"/></a>} key="5"></TabPane>
                        <TabPane tab={<span className="c06 airtab-item">Open Box <img src={soon} alt="" className="soon"/></span>} disabled key="4"></TabPane>
                    </Tabs>
                </div>

                <div className="w100  flex flex-column flex-center">
                    {activeKey == 1 ?<Odysey account={props.account} loading={loading} code={code} showopacity banner={<img src={require('../assets/images/newairdrop/man.png')} />}/>:
                    // activeKey == 2 ?<Odysey account={props.account} code={code} banner={<div className="p-t-45 p-b-97 flex flex-center"><img src={require('../assets/images/newairdrop/blur.png')} /></div>}/>:
                    activeKey == 3 ? <Claim account={props.account} code={code}/>:<div className="empty"></div> }
                    
                <div className="ta fz-18 cf lh-20 m-fz-14 m-t-20 m-m-t-10">
                   This Airdrop, a total of &nbsp;
                    <u className='ce'>
                        <Activenumber classes="ce fwb italic fz-22 m-fz-16"  value={totalAirdrop} /></u>&nbsp;
                        addresses are eligible for airdrop
                </div>
                    <Progress/>
                    <Rules/>
                    <Rewards/>
                    
                </div>
            </div>
            <Mintfooter />


        </div>
    )
})