import { providerOptions } from "../wallet/helper/providerOptions";

export const baseUrl = 'https://api.kepler.homes'
export const chainId = 42161;
export const arb_network = {
  name: 'ARB',
  params: {
    chainId: '42161',
    chainName: 'Arbitrum One',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'GoerliETH',
      decimals: 18,
    },
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    blockExplorerUrls: ['https://arbiscan.io'],
  },
  networkId: 42161,
  httpProviderURL: 'https://arb1.arbitrum.io/rpc',
  connectors: {
    network: 'arbitrum',
    cacheProvider: true,
    providerOptions: providerOptions({
      walletconnectOptions: {
        rpc: {
          42161: 'https://arb1.arbitrum.io/rpc'
        }
      }
    })
  }
}